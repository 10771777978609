<h1 mat-dialog-title>
    {{ (similar ? 'Atualizar similar' : 'Adicionar item similar') | translate }}
</h1>
<form [formGroup]="form"
      autocomplete="off">
    <mat-dialog-content class="item-dialog">
        <mat-form-field class="col-12">
            <mat-label>Similar</mat-label>
            <input #searchInput
                   matInput
                   placeholder="Digite código ou descrição da peça similar"
                   formControlName="item"
                   [matAutocomplete]="auto"
                   required />
            <mat-autocomplete #auto="matAutocomplete"
                              [displayWith]="displayFn">
                <mat-option *ngFor="let item of filteredItems | partFilter : searchInput.value"
                            [value]="item">
                    {{ item.code }} - {{ item.description }}
                </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="form.get('item').hasError('notFound')">Item não encontrado</mat-error>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button aria-label="cancel"
                mat-raised-button
                color="accent"
                type="button"
                (click)="close()">
            {{ 'ACTION.SHARED.CANCEL' | translate }}
        </button>
        <button aria-label=save
                mat-raised-button
                color="primary"
                type="button"
                (singleClick)="onSubmit()"
                [disabled]="form.invalid">
            {{ 'ACTION.SHARED.SAVE_CHANGES' | translate }}
        </button>
    </mat-dialog-actions>
</form>