import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Part } from '../../../interface/part';
import { PartService } from '../../../service/part.service';

@Component({
  selector: 'app-similar-edit',
  templateUrl: './similar-edit.component.html',
  styleUrl: './similar-edit.component.scss'
})
export class SimilarEditComponent implements OnInit {

  form: FormGroup;
  similar: any;

  filteredItems = []
  items = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { item: Part, similar: Part },
    private dialogRef: MatDialogRef<SimilarEditComponent>,
    private formBuilder: FormBuilder,
    private partService: PartService,
  ) {
    if (data) {
      this.similar = data.similar
    }
  }

  ngOnInit() {
    this.getItems();
    this.buildForm()
  }

  private async getItems() {
    await this.partService.getAll();
    this.items = this.partService.parts;

    if (this.data && this.data.item) {
      for (const part of this.items) {
        let include = true;

        // the item cannot be similar to itself
        if (part.id === this.data.item.id) {
          include = false
        }

        // remove already similar parts
        for (const similar of this.data.item.parts) {
          if (similar.id === part.id) {
            include = false;
          }
        }

        if (include) {
          this.filteredItems.push(part)
        }
      }
    }
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      item: ["", Validators.required, this.isPart]
    })
  }

  public onSubmit() {
    this.dialogRef.close(this.form.getRawValue())
  }

  public displayFn(item: Part): string {
    return item && item.description ? item.description : '';
  }

  close() {
    this.dialogRef.close()
  }


  async isPart(control: AbstractControl) {
    if (!control.value.id) {
      return { notFound: true };
    }
    return null;
  };

}

